import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
// import Modal from "../elements/Modal";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({ className, topOuterDivider, bottomOuterDivider, topDivider, bottomDivider, hasBgColor, invertColor, ...props }) => {
  const openModal = (e) => {
    e.preventDefault();
  };

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames("hero-inner section-inner", topDivider && "has-top-divider", bottomDivider && "has-bottom-divider");

  return (
    <section {...props} className={outerClasses}>
      <div className='container-sm'>
        <div className={innerClasses}>
          <div className='hero-content'>
            <div>
              <Image src={require("./../../assets/images/0x/banner_2.png")} alt='Features tile icon 01' width={1200} height={750} />
            </div>
            <br />
            <h1 className='mainTitle mt-0 mb-16 reveal-from-bottom' data-reveal-delay='200'>
              0x GANG
            </h1>

            <div className='container-xs'>
              <p className='m-0 mb-32 reveal-from-bottom' data-reveal-delay='400'>
                Not affilliated with Ape Gang <br></br>
                Brand new trait randomisation <br></br>
                New 1/1's + new traits added <br />
                50% of proceeds hard coded to go to the community wallet
              </p>

              <div className='reveal-from-bottom' data-reveal-delay='600'>
                <ButtonGroup>
                
                  <Button tag='a' color='primary' target='_blank' href="/mint" wideMobile >
                    Mint 6pm EST 3.1
                  </Button>
                  <Button tag='a' color='dark' target='_blank' wideMobile href='https://medium.com/@0xGang/different-tribe-same-bloodline-3c9797cb8398'>
                    Read More
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

//ADD href="/mint" to line 57

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
