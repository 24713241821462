import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({ className, topOuterDivider, bottomOuterDivider, topDivider, bottomDivider, hasBgColor, invertColor, pushLeft, ...props }) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames("features-tiles-inner section-inner pt-0", topDivider && "has-top-divider", bottomDivider && "has-bottom-divider");

  const tilesClasses = classNames("tiles-wrap center-content", pushLeft && "push-left");

  const sectionHeader = {
    title: "Different Tribe, Same Bloodline.",
    // paragraph:
    //   "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum — semper quis lectus nulla at volutpat diam ut venenatis.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className='container'>
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className='center-content' />
          <div className={tilesClasses}>
            <div className='tiles-item reveal-from-bottom'>
              <div className='tiles-item-inner'>
                <div className='features-tiles-item-header'>
                  <div className='features-tiles-item-image mb-16'>
                    <Image src={require("./../../assets/images/0x/robot9.png")} alt='Features tile icon 01' width={500} height={500} />
                  </div>
                </div>
                <div className='features-tiles-item-content'>
                  <h4 className='mt-0 mb-8'>Brand new trait randomisation</h4>
                  <p className='m-0 text-sm'>Each 0xGang is a brand new randomisation from the original AG collection - NO duplicates.</p>
                </div>
              </div>
            </div>

            <div className='tiles-item reveal-from-bottom' data-reveal-delay='200'>
              <div className='tiles-item-inner'>
                <div className='features-tiles-item-header'>
                  <div className='features-tiles-item-image mb-16'>
                    <Image src={require("./../../assets/images/0x/Full joker.png")} alt='Features tile icon 02' width={500} height={500} />
                  </div>
                </div>
                <div className='features-tiles-item-content'>
                  <h4 className='mt-0 mb-8'>New 1/1's</h4>
                  <p className='m-0 text-sm'>
                    0xGang features brand new 1/1 apes including Joker,Jokong+Rikongs apes and more. Also includes zombie,robot and alien 1ts.
                  </p>
                </div>
              </div>
            </div>

            <div className='tiles-item reveal-from-bottom' data-reveal-delay='400'>
              <div className='tiles-item-inner'>
                <div className='features-tiles-item-header'>
                  <div className='features-tiles-item-image mb-16'>
                    <Image src={require("./../../assets/images/0x/BlueMatchingHigh.png")} alt='Features tile icon 03' width={500} height={500} />
                  </div>
                </div>
                <div className='features-tiles-item-content'>
                  <h4 className='mt-0 mb-8'>New fur</h4>
                  <p className='m-0 text-sm'>
                    We removed some traits from the original AG collection and added some amazing new ones, including the rare blue fur.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
