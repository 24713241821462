import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames("features-split-inner section-inner", topDivider && "has-top-divider", bottomDivider && "has-bottom-divider");

  const splitClasses = classNames("split-wrap", invertMobile && "invert-mobile", invertDesktop && "invert-desktop", alignTop && "align-top");

  const sectionHeader = {
    title: "50% of all mint sales and royalties go to Ape Gang Community wallet.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className='container'>
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className='center-content' />
          <div className={splitClasses}>
            <div className='split-item'>
              <div className='split-item-content center-content-mobile reveal-from-left' data-reveal-container='.split-item'>
                <h3 className='mt-0 mb-12'>Payout addresses are immutable</h3>
                <p className='m-0'>
                  0x Gang has choosen to hard code the payout addresses to the smart contract. This means it can never be altered or removed. When the
                  contract's withdraw method is executed, Ape Gang will get 50% off the top sent to their community wallet. Refer to line 1281 on 0X Gang's smart
                  contract.
                  <a href='https://etherscan.io/address/0x67984e19f5d5aeedd774b3d71e2554d0ddeef496#code' alt='Etherscan'>
                    {" "}
                    0x Gang
                  </a>
                </p>
              </div>
              <div
                className={classNames("split-item-image center-content-mobile reveal-from-bottom", imageFill && "split-item-image-fill")}
                data-reveal-container='.split-item'
              >
                <Image src={require("./../../assets/images/0x/Main3.png")} alt='Features split 01' width={528} height={396} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
